import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Label, Icon, Modal, Button } from "semantic-ui-react";

import Service from "./service";
import { setAlert } from "../App/store";

class DealersTable extends Component {
  state = {
    dealers: this.props.dealers
      .map(dealer => {
        dealer.dealerName = dealer.dealerName.charAt(0).toUpperCase() + dealer.dealerName.slice(1);
        dealer.locations.forEach(location => (location.name = location.name.charAt(0).toUpperCase() + location.name.slice(1)));
        dealer.locations.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return dealer;
      })
      .sort((a, b) => (a.dealerName > b.dealerName ? 1 : b.dealerName > a.dealerName ? -1 : 0)),
    selectedDealer: null,
    selectedLocation: null,
    showDealerInfo: false,
    showImportLog: false,
    logs: [],
    expanded: {},
    areLocationsOpen: this.props.areLocationsOpen,
  };

  componentWillReceiveProps(nextProps) {
    let { dealers, expanded } = this.state;
    if (nextProps.areLocationsOpen) {
      dealers.forEach((d, i) => {
        expanded[i] = true;
      });
    } else {
      expanded = {};
    }

    let sortedDealers = nextProps.dealers
      .map(dealer => {
        dealer.dealerName = dealer.dealerName.charAt(0).toUpperCase() + dealer.dealerName.slice(1);
        dealer.locations.forEach(location => (location.name = location.name.charAt(0).toUpperCase() + location.name.slice(1)));
        dealer.locations.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return dealer;
      })
      .sort((a, b) => (a.dealerName > b.dealerName ? 1 : b.dealerName > a.dealerName ? -1 : 0));

    this.setState({ areLocationsOpen: nextProps.areLocationsOpen, expanded, dealers: sortedDealers });
  }

  checkImporterDate = item => {
    if (item) {
      let hours = moment.duration(moment().diff(moment(item.last_import_at))).asHours();
      if (hours >= 2) {
        return "highlight-red-row";
      }
    }
  };

  handleShowDealerInfo = selectedDealer => {
    if (selectedDealer) {
      this.setState({ selectedDealer, showDealerInfo: true });
    }
  };

  handleShowImportLog = (selectedDealer, selectedLocation) => {
    this.setState({ selectedDealer, selectedLocation }, () => {
      Service.getImportLogForLocation(selectedLocation.id)
        .then(response => {
          const logs = response && response.data && response.data.logs ? response.data.logs : [];
          this.setState({ showImportLog: true, logs });
        })
        .catch(err => {
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
          console.error("Error getting location logs.", err);
          this.setState({ showImportLog: false });
        });
    });
  };

  handleHideDealerInfo = () => {
    this.setState({ showDealerInfo: false, selectedDealer: null });
  };

  handleHideImportLogs = () => {
    this.setState({ showImportLog: false, selectedDealer: null, selectedLocation: null });
  };

  renderDealerInfo = () => {
    const { selectedDealer, showDealerInfo } = this.state;
    const { t } = this.props;
    return (
      showDealerInfo &&
      selectedDealer && (
        <Modal open={showDealerInfo} closeOnDimmerClick={false}>
          <Modal.Content>
            <section>
              <p>
                {t("Dealer").message || "Dealer"}
                <strong>{selectedDealer.dealerName}</strong>
              </p>
              <p>
                {t("ID").message || "ID"}
                <strong>{selectedDealer.all_info.id}</strong>
              </p>
              <p>
                {t("system_admin_name").message || "Admin"}
                <strong>{selectedDealer.info.name}</strong>
              </p>
              <p>
                {t("system_admin_email").message || "Admin e-mail"}
                <strong>{selectedDealer.info.email}</strong>
              </p>
              <p>
                {t("system_admin_phone").message || "Phone"}
                <strong>{selectedDealer.info.phone_fr}</strong>
              </p>
              <p>
                {t("dms_server_information").message || "DMS Info"}
                <strong>{selectedDealer.info.dms_info}</strong>
              </p>
              <p>
                {t("network_info").message || "Network Info"}
                <strong>{selectedDealer.info.network_info}</strong>
              </p>
            </section>
          </Modal.Content>
          <Modal.Actions>
            <Button negative color="red" inverted onClick={this.handleHideDealerInfo}>
              <Icon name="cancel" /> {t("close").message || "Close"}
            </Button>
          </Modal.Actions>
        </Modal>
      )
    );
  };

  renderImportLog = () => {
    const { selectedDealer, selectedLocation, showImportLog, logs } = this.state;
    const { t } = this.props;
    return (
      showImportLog && (
        <Modal open={showImportLog} closeOnDimmerClick={false}>
          <Modal.Header>
            {`${selectedDealer.dealerName} - ${selectedLocation.name} Import Log`}
            {logs && logs.length > 0 && (
              <p className="-pull-right">
                Last import :<strong>{moment(logs[0].last_import_at).format("DD.MM.YYYY [at] HH:mm")}</strong>
              </p>
            )}
          </Modal.Header>
          <Modal.Content>
            {(!logs || logs.length === 0) && (
              <section>
                <p>
                  <strong>No logs for this location!</strong>
                </p>
              </section>
            )}
            <section>
              {logs[0] &&
                logs[0].import_updates &&
                logs[0].import_updates.length > 0 &&
                logs[0].import_updates.map(l => (
                  <p>
                    WO : <strong>{l.wo_nr}</strong> External ID: <strong>{l.external_id}</strong>
                  </p>
                ))}
            </section>
          </Modal.Content>
          <Modal.Actions>
            <Button negative color="red" inverted onClick={this.handleHideImportLogs}>
              <Icon name="cancel" /> {t("close").message || "Close"}
            </Button>
          </Modal.Actions>
        </Modal>
      )
    );
  };

  countBadImporterDates = locations => {
    let c = 0;
    if (locations) {
      locations.forEach(l => {
        let hours = moment.duration(moment().diff(moment(l.last_import_at))).asHours();
        if (hours >= 1) {
          c++;
        }
      });
    }
    return c;
  };

  isDealerExactInvoicingEnabled = dealer => dealer.all_info.exact_account_id && dealer.all_info.is_exact_invoicing_enabled;

  renderDealerExactInvoicingEnabledIcons = dealer => {
    const activeLocations = dealer?.locations?.filter(l => l.active);

    const isEnabled =
      this.isDealerExactInvoicingEnabled(dealer) ||
      (!!activeLocations?.length && activeLocations.every(location => location.is_exact_invoicing_enabled && location.exact_account_id));

    if (isEnabled) return <Icon name="check" color="green" />;

    return <Icon name="close" color="red" />;
  };

  renderLocationExactInvoicingEnabledIcons = (location, dealer) => {
    const isEnabled = location.is_exact_invoicing_enabled && (location.exact_account_id || this.isDealerExactInvoicingEnabled(dealer));

    if (isEnabled) return <Icon name="check" color="green" />;

    return <Icon name="close" color="red" />;
  };

  render() {
    let { t } = this.props;
    const { showDealerInfo, showImportLog, expanded, dealers } = this.state;

    return (
      <div className="DealersTable">
        <ReactTable
          className="ReactTable -highlight"
          data={dealers || []}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={dealers.length}
          pageSize={dealers.length}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="sitemap" style={{ fontSize: "1.75em", marginTop: "250px" }} />
              <p>{t("no_dealers").message || "No dealers"}</p>
            </div>
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (!column.expander && column.id !== "action") {
                  this.props.onRowClick(column.id, rowInfo);
                }
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };
          }}
          getTrProps={(state, rowInfo, column) => {
            if (!rowInfo) {
              return {};
            }
            return {
              style: {
                borderLeft: `4px solid ${rowInfo.original.dealerActive ? "green" : "red"}`,
              },
            };
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: t("dealer").message || "Dealer",
              accessor: "dealerName",
            },
            {
              Header: t("admin").message || "Admin",
              accessor: "info.name",
            },
            {
              id: "invoicing",
              Header: t("invoicing").message || "Invoicing",
              accessor: d => <div className="invoicing-icon-box">{this.renderDealerExactInvoicingEnabledIcons(d)}</div>,
            },
            {
              Header: t("email").message || "Email",
              accessor: "info.email",
            },
            {
              Header: t("phone").message || "Phone",
              accessor: "info.phone_fr",
            },
            {
              Header: t("dms_info").message || "DMS Info",
              accessor: "info.dms_info",
            },
            {
              id: "badImports",
              Header: t("bad_imports").message || "Bad imports",
              maxWidth: 75,
              accessor: d => <Label circular color="red">{`${this.countBadImporterDates(d.locations)}`}</Label>,
            },
            {
              Header: t("network_info").message || "Network Info",
              accessor: "info.network_info",
            },
            {
              id: "action",
              Header: "",
              maxWidth: 80,
              accessor: d => (
                <div className="-pull-right">
                  <Icon name="info circle" size="large" onClick={() => this.handleShowDealerInfo(d)} />
                  <Icon color="green" name="plus circle" size="large" onClick={() => this.props.onNewLocationClick(d)} />
                </div>
              ),
            },
          ]}
          expanded={expanded}
          onExpandedChange={expanded => this.setState({ expanded })}
          SubComponent={row => {
            return (
              <div style={{ padding: "20px" }}>
                <ReactTable
                  className="ReactTable -highlight"
                  data={row.original.locations}
                  sortable={false}
                  resizable={false}
                  noDataText={t("no_imports").message || "No imports"}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (!(column.id === "importer_version" || column.id === "action")) {
                          this.props.onLocationClick(column.id, rowInfo);
                        }
                        // IMPORTANT! React-Table uses onClick internally to trigger
                        // events like expanding SubComponents and pivots.
                        // By default a custom 'onClick' handler will override this functionality.
                        // If you want to fire the original onClick handler, call the
                        // 'handleOriginal' function.
                        if (handleOriginal) {
                          //    handleOriginal();
                        }
                      },
                    };
                  }}
                  getTrProps={(state, rowInfo, column) => {
                    if (!rowInfo) {
                      return {};
                    }

                    return {
                      style: {
                        borderLeft: `4px solid ${rowInfo.original.active ? "green" : "red"}`,
                      },
                    };
                  }}
                  column={{
                    ...ReactTableDefaults.column,
                    headerClassName: "ReactTable__column-header",
                    className: "ReactTable__column",
                  }}
                  columns={[
                    {
                      id: "location",
                      Header: t("Location").message || "Location",
                      className: "-text-overflow-unset",
                      accessor: d => <span className={this.checkImporterDate(d)}>{d.name}</span>,
                    },
                    {
                      id: "importer_version",
                      Header: t("importer").message || "Importer V#",
                      className: "-text-overflow-unset",
                      accessor: d => (
                        <span className={this.checkImporterDate(d)}>
                          <Icon
                            name="cloud upload"
                            className="-cursor-pointer-no-color"
                            title="Show import log"
                            onClick={() => this.handleShowImportLog(row.original, d)}
                          />
                          {d.importer_version}
                        </span>
                      ),
                    },
                    {
                      id: "invoicing",
                      Header: t("invoicing_enabled").message || "Invoicing Enabled",
                      accessor: d => <div className="invoicing-icon-box">{this.renderLocationExactInvoicingEnabledIcons(d, row.original)}</div>,
                    },
                    {
                      id: "action",
                      Header: "",
                      accessor: d => d.accept_network_metrics && <Icon name="wifi" title="Wi-Fi metrics are being collected" />,
                    },
                  ]}
                  showPagination={false}
                  showPageSizeOptions={false}
                  pageSize={row.original.locations.length}
                />
              </div>
            );
          }}
        />
        {showDealerInfo && this.renderDealerInfo()}
        {showImportLog && this.renderImportLog()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DealersTable));
