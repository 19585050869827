import React, { Component, createRef } from "react";
import AlertContainer from "react-alert";
import { connect } from "react-redux";
import { Button, Checkbox, Form, Header, Icon, Message, Modal, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { updateLocation as globalLocationUpdate } from "../../modules/App/store";
import UserInput from "../UserInput";

import Service from "./service";

import "./InvoicingSettings.scss";

class InvoicingSettings extends Component {
  state = {
    requiredFieldsError: false,
    accountModalOpen: false,
    emailInvalid: false,
    isSavingExactAccount: false,
    isLinkingExactAccount: false,
    isRetrievingAccount: false,
    exactAccountId: "",
  };

  msg = createRef();
  modalMsg = createRef();

  componentDidMount() {
    this.setState({ exactAccountId: this.props.location.exact_account_id || "" });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.id !== this.props.location.id) this.setState({ exactAccountId: this.props.location.exact_account_id || "" });
  }

  handleInputChange = (_e, { name, value }) => {
    const { location } = this.props;
    const updatedLocation = { ...location, [name]: value };

    this.setState({ requiredFieldsError: false, emailInvalid: false });
    this.props.onLocationChange(updatedLocation);
  };

  handleExactAccountIdChange = (_e, { value }) => {
    this.setState({ exactAccountId: value });
  };

  handleCheckboxChange = async (_e, { name, checked }) => {
    const { location } = this.props;

    this.props.onLocationChange({ ...location, [name]: checked });
  };

  handleOpenAccountModal = () => {
    const { location } = this.props;

    if (!location.exact_account_id) {
      this.setState({ accountModalOpen: true });
      return;
    }

    this.setState({ isRetrievingAccount: true }, async () => {
      try {
        const response = await Service.retrieveInvoicingAccount({ dealer_location_id: location.id });

        const invoicingAccount = response.data?.data || {};

        const exactLocationAccount = {
          exact_invoicing_company_name: invoicingAccount.Name,
          exact_invoicing_email: invoicingAccount.Contact.Email,
          exact_vat_number: invoicingAccount.VATNumber,
          exact_invoicing_address: invoicingAccount.AddressLine1,
          exact_invoicing_zip_code: invoicingAccount.Postcode,
          exact_invoicing_city: invoicingAccount.City,
          exact_invoicing_initials: invoicingAccount.Contact.Initials,
          exact_invoicing_last_name: invoicingAccount.Contact.LastName,
          exact_invoicing_country: invoicingAccount.Country,
          exact_chamber_of_commerce: invoicingAccount.ChamberOfCommerce,
        };

        this.props.onLocationChange({ ...location, ...exactLocationAccount });
        this.setState({ isRetrievingAccount: false, accountModalOpen: true });
      } catch (error) {
        const errorMsg = typeof error.response?.data === "string" ? error.response.data : error.response.data?.errors?.[0] ?? "Failed to retrieve invoicing account";
        this.modalMsg.current.show(errorMsg, { type: "error" });
        this.setState({ isRetrievingAccount: false });
      }
    });
  };

  validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  saveAccount = () => {
    const { location } = this.props;
    const requiredFields = [
      "exact_invoicing_company_name",
      "exact_invoicing_email",
      "exact_vat_number",
      "exact_invoicing_address",
      "exact_invoicing_country",
      "exact_invoicing_zip_code",
      "exact_invoicing_city",
      "exact_invoicing_initials",
      "exact_invoicing_last_name",
      "exact_chamber_of_commerce",
    ];

    const missingFields = requiredFields.some(field => !location[field]);

    if (missingFields) {
      this.setState({ requiredFieldsError: true });
      return;
    }

    if (!this.validateEmail(location.exact_invoicing_email)) {
      this.setState({ emailInvalid: true });
      return;
    }

    this.setState({ isSavingExactAccount: true }, async () => {
      try {
        const response = await Service.saveInvoicingAccount({
          exact_invoicing_company_name: location.exact_invoicing_company_name,
          exact_invoicing_email: location.exact_invoicing_email,
          exact_vat_number: location.exact_vat_number,
          exact_invoicing_address: location.exact_invoicing_address,
          exact_invoicing_zip_code: location.exact_invoicing_zip_code,
          exact_invoicing_city: location.exact_invoicing_city,
          exact_invoicing_initials: location.exact_invoicing_initials,
          exact_invoicing_last_name: location.exact_invoicing_last_name,
          exact_invoicing_country: location.exact_invoicing_country,
          exact_chamber_of_commerce: location.exact_chamber_of_commerce,
          dealer_location_id: location.id,
        });

        const updatedLocation = {
          ...location,
          exact_account_id: response.data?.data?.exact_account_id || "",
        };

        this.props.onLocationChange(updatedLocation);
        this.props.globalLocationUpdate(updatedLocation);
        this.setState({ accountModalOpen: false, isSavingExactAccount: false, exactAccountId: "" });
      } catch (error) {
        const errorMsg = typeof error.response?.data === "string" ? error.response.data : error.response.data?.errors?.[0] ?? "Failed to create invoicing account";

        this.modalMsg.current.show(errorMsg, { type: "error" });
        this.setState({ isSavingExactAccount: false });
      }
    });
  };

  linkAccount = () => {
    const { location } = this.props;
    const { exactAccountId } = this.state;

    this.setState({ isLinkingExactAccount: true }, async () => {
      try {
        await Service.linkInvoicingAccount({ dealer_location_id: location.id, account_id: exactAccountId });

        const updatedLocation = {
          ...location,
          exact_account_id: exactAccountId,
        };
        this.props.onLocationChange(updatedLocation);
        this.props.globalLocationUpdate(updatedLocation);
        this.setState({ isLinkingExactAccount: false, exactAccountId: "" });
      } catch (error) {
        const errorMsg = typeof error.response?.data === "string" ? error.response.data : error.response.data?.errors?.[0] ?? "Failed to link invoicing account";

        this.msg.current.show(errorMsg, { type: "error" });
        this.setState({ isLinkingExactAccount: false });
      }
    });
  };

  renderModalAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 10000,
      transition: "fade",
    };

    return (
      <div className="react-alert-container">
        <AlertContainer ref={this.modalMsg} {...props} />
      </div>
    );
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 10000,
      transition: "fade",
    };

    return (
      <div className="react-alert-container">
        <AlertContainer ref={this.msg} {...props} />
      </div>
    );
  };

  render() {
    const { location, dealer, t } = this.props;
    const { requiredFieldsError, accountModalOpen, exactAccountId, emailInvalid, isSavingExactAccount, isRetrievingAccount, isLinkingExactAccount } = this.state;

    const isDealerExactConfigured = dealer.exact_account_id && dealer.is_exact_invoicing_enabled;

    return (
      <>
        <Segment>
          <Header as="h5">
            <Icon name="file invoice dollar" />
            <Header.Content>{t("invoicing_settings").message || "Invoicing Settings"}</Header.Content>
          </Header>

          <Segment>
            <Form>
              {!isDealerExactConfigured && (
                <>
                  <Form.Field className="full-width-input" required>
                    <label>{t("exact_account_id").message || "Exact Account ID"}</label>
                    <UserInput
                      value={location.exact_account_id || exactAccountId}
                      placeholder={t("exact_account_id").message || "Exact Account ID"}
                      onChange={this.handleExactAccountIdChange}
                      readOnly={Boolean(location.exact_account_id)}
                    />
                  </Form.Field>

                  <div className="ExactInvoicing__action-group">
                    <div className="ExactInvoicing__action-group-buttons">
                      <Button onClick={this.handleOpenAccountModal} color="green" loading={isRetrievingAccount} disabled={isRetrievingAccount}>
                        {location.exact_account_id
                          ? t("update_exact_account").message || "Update Exact Account"
                          : t("create_exact_account").message || "Create Exact Account"}
                      </Button>

                      <Button
                        onClick={this.linkAccount}
                        loading={isLinkingExactAccount}
                        disabled={location.exact_account_id || isLinkingExactAccount || !exactAccountId}
                        color="green"
                      >
                        {t("save").message || "Save"}
                      </Button>
                    </div>
                  </div>
                </>
              )}
              <div className={`ExactInvoicing__action-group-checkbox ${isDealerExactConfigured ? "checkbox-only" : ""}`}>
                <Checkbox
                  toggle
                  name="is_exact_invoicing_enabled"
                  checked={location.is_exact_invoicing_enabled}
                  onChange={this.handleCheckboxChange}
                  label={t("enable_invoicing").message || "Enable Invoicing"}
                />
              </div>
            </Form>
          </Segment>
        </Segment>

        <Modal open={accountModalOpen} onClose={() => this.setState({ accountModalOpen: false })} size="small">
          <Modal.Header>
            {location.exact_account_id ? t("update_exact_account").message || "Update Exact Account" : t("create_exact_account").message || "Create Exact Account"}
          </Modal.Header>
          <Modal.Content className="ExactModal__content">
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_company_name}>
                  <label>{t("company_name").message || "Company name"}</label>
                  <UserInput
                    name="exact_invoicing_company_name"
                    placeholder={t("company_name").message || "Company name"}
                    value={location.exact_invoicing_company_name}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>

                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_country}>
                  <label>{t("exact_country").message || "Country"}</label>
                  <UserInput
                    name="exact_invoicing_country"
                    placeholder={t("exact_country").message || "Country"}
                    value={location.exact_invoicing_country}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_email}>
                  <label>{t("invoice_email").message || "Invoice Email"}</label>
                  <UserInput
                    name="exact_invoicing_email"
                    placeholder={t("invoice_email").message || "Invoice Email"}
                    value={location.exact_invoicing_email}
                    onChange={this.handleInputChange}
                  />
                  {emailInvalid && (
                    <Message negative size="tiny">
                      <p>{t("email_address_invalid").message || "Invalid email address"}</p>
                    </Message>
                  )}
                </Form.Field>

                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_city}>
                  <label>{t("city").message || "City"}</label>
                  <UserInput
                    name="exact_invoicing_city"
                    placeholder={t("city").message || "City"}
                    value={location.exact_invoicing_city}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field required error={requiredFieldsError && !location.exact_vat_number}>
                  <label>{t("vat_number").message || "VAT-Number"}</label>
                  <UserInput
                    name="exact_vat_number"
                    placeholder={t("vat_number").message || "VAT-Number"}
                    value={location.exact_vat_number}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>

                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_address}>
                  <label>{t("address").message || "Adress"}</label>
                  <UserInput
                    name="exact_invoicing_address"
                    placeholder={t("address").message || "Address"}
                    value={location.exact_invoicing_address}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field className="recipient-field" required>
                  <label>{t("invoice_recipient").message || "Invoice recipient"}</label>
                  <Form.Group>
                    <Form.Field width={8} error={requiredFieldsError && !location.exact_invoicing_initials}>
                      <UserInput
                        name="exact_invoicing_initials"
                        placeholder={t("initials").message || "Initials"}
                        value={location.exact_invoicing_initials}
                        onChange={this.handleInputChange}
                      />
                    </Form.Field>
                    <Form.Field width={8} error={requiredFieldsError && !location.exact_invoicing_last_name}>
                      <UserInput
                        name="exact_invoicing_last_name"
                        placeholder={t("surname").message || "Surname"}
                        value={location.exact_invoicing_last_name}
                        onChange={this.handleInputChange}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Field>

                <Form.Field required error={requiredFieldsError && !location.exact_invoicing_zip_code}>
                  <label>{t("zip_code").message || "Zip code"}</label>
                  <UserInput
                    name="exact_invoicing_zip_code"
                    placeholder={t("zip_code").message || "Zip code"}
                    value={location.exact_invoicing_zip_code}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={8} required error={requiredFieldsError && !location.exact_chamber_of_commerce}>
                  <label>{t("chamber_of_commerce").message || "Chamber of Commerce"}</label>

                  <UserInput
                    name="exact_chamber_of_commerce"
                    placeholder={t("chamber_of_commerce").message || "Chamber of Commerce"}
                    value={location.exact_chamber_of_commerce}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>

              {requiredFieldsError && (
                <Message negative>
                  <p>{t("required_fields_error").message || "Please fill all required fields"}</p>
                </Message>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ accountModalOpen: false, requiredFieldsError: false })} color="grey">
              {t("cancel").message || "Cancel"}
            </Button>
            <Button onClick={this.saveAccount} loading={isSavingExactAccount} disabled={isSavingExactAccount} color="green">
              {t("save").message || "Save"}
            </Button>
          </Modal.Actions>
          {this.renderModalAlert()}
        </Modal>

        {this.renderAlert()}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    globalLocationUpdate: location => dispatch(globalLocationUpdate(location)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(InvoicingSettings));
