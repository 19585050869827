const ENVIRONMENTS = {
  dev: {
    apiBaseB: "https://clairedev.nl/api/v2",
    //apiBaseB: "http://localhost:8080/api/v2",
    ccBaseUrl: "https://comm.clairedev.nl",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    envName: "dev",
    zohoPrefix: "dev",
    searchEnv: "dev",
    storageI18n: "https://storage.googleapis.com/clairei18n",
    storageDailyCheckExport: "https://storage.googleapis.com/claire-analytic-export-dev/",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    gaTrackingCode: "UA-114415392-2",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000030544001?orgId=20066420619",
    wheelTireOrderPortalURL: "https://test-011.webdis.nl",
    bandenExpressOrderPortalURL: "https://test-081.bexweb.nl",
    tyreteamBaseURL: "https://tyreteam.clairedev.nl/v1",
    keyloopBaseURL: "https://keyloop.clairedev.nl/v1",
    shareboxBaseURL: "https://sharebox.clairedev.nl/v1",
    dmsv3BaseUrl: "https://clairedev.nl/dmsv3",
    twilioBaseUrl: "https://twilio.clairedev.nl/v1",
    authBaseUrl: "https://auth.clairedev.nl/v1",
    dayplannerBaseUrl: "https://appointment.clairedev.nl/v1/dayplanner",
    dcBaseUrl: "https://desk.clairedev.nl/v1",
    appointmentApiBase: "https://appointment.clairedev.nl/v1",
    reportsBaseUrl: "https://report.clairedev.nl/v1",
    dealerBaseUrl: "https://dealer.clairedev.nl/v1",
    autoflexBaseUrl: "https://autoflex.clairedev.nl/v1",
    carBaseUrl: "https://car.clairedev.nl/v1",
    keylockerBase: "https://keylocker.clairedev.nl/v1",
    acsesBaseUrl: "https://acses.clairedev.nl/v1",
    kioskBaseUrl: "https://kiosk.clairedev.nl/v1",
    importerBaseUrl: "https://importer.clairedev.nl/v3",
    nextlaneBaseUrl: "https://nextlane.clairedev.nl/v1",
    customComBaseUrl: "https://customcom.clairedev.nl/v1",
    systemBase: "https://system.clairedev.nl/v1",
    exactBase: "https://exact.clairedev.nl/v1",
    wincarBaseURL: "https://wincar.clairedev.nl/v1",
  },
  local: {
    apiBaseB: "http://localhost:8080/api/v2",
    ccBaseUrl: "https://comm.clairedev.nl",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    envName: "dev",
    searchEnv: "dev",
    storageI18n: "https://storage.googleapis.com/clairei18n",
    storageDailyCheckExport: "https://storage.googleapis.com/claire-analytic-export-dev/",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    gaTrackingCode: "UA-114415392-2",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000030544001?orgId=20066420619",
    keylockerBase: "https://keylocker.clairedev.nl/v1",
    acsesBaseUrl: "https://acses.clairedev.nl/v1",
    kioskBaseUrl: "https://kiosk.clairedev.nl/v1",
    importerBaseUrl: "https://importer.clairedev.nl/v3",
    exactBase: "https://exact.clairedev.nl/v1",
  },
  prod: {
    apiBaseB: "https://claireit.com/api/v2",
    ccBaseUrl: "https://mycarcheck.pro",
    clientID: "674901036874-hrv3lhqlkp3b0uh8lhoaj0hlmrqd37it.apps.googleusercontent.com",
    envName: "prod",
    zohoPrefix: "pro",
    searchEnv: "prod",
    storageI18n: "https://storage.googleapis.com/clairei18n-prod",
    storageDailyCheckExport: "https://storage.googleapis.com/claire-car-check-daily-export/",
    webSocketEndpoint: "wss://notifier.claireit.com",
    gaTrackingCode: "UA-114415392-1",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000031001003?orgId=20066420619",
    wheelTireOrderPortalURL: "https://www.webdis.nl",
    bandenExpressOrderPortalURL: "https://www.bexweb.nl",
    tyreteamBaseURL: "https://tyreteam.claireit.com/v1",
    keyloopBaseURL: "https://keyloop.claireit.com/v1",
    shareboxBaseURL: "https://sharebox.claireit.com/v1",
    dmsv3BaseUrl: "https://claireit.com/dmsv3",
    twilioBaseUrl: "https://twilio.claireit.com/v1",
    authBaseUrl: "https://auth.claireit.com/v1",
    dayplannerBaseUrl: "https://appointment.claireit.com/v1/dayplanner",
    dcBaseUrl: "https://desk.claireit.com/v1",
    appointmentApiBase: "https://appointment.claireit.com/v1",
    reportsBaseUrl: "https://report.claireit.com/v1",
    dealerBaseUrl: "https://dealer.claireit.com/v1",
    autoflexBaseUrl: "https://autoflex.claireit.com/v1",
    carBaseUrl: "https://car.claireit.com/v1",
    keylockerBase: "https://keylocker.claireit.com/v1",
    acsesBaseUrl: "https://acses.claireit.com/v1",
    kioskBaseUrl: "https://kiosk.claireit.com/v1",
    importerBaseUrl: "https://importer.claireit.com/v3",
    nextlaneBaseUrl: "https://nextlane.claireit.com/v1",
    customComBaseUrl: "https://customcom.claireit.com/v1",
    systemBase: "https://system.claireit.com/v1",
    exactBase: "https://exact.claireit.com/v1",
    wincarBaseURL: "https://wincar.clairedev.nl/v1",
  },
};

const ENV = ENVIRONMENTS[process.env.REACT_APP_ENV];

export default ENV;
