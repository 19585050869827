import React, { useEffect, useState } from "react";
import { Grid, Icon, Label, Dropdown, Popup, Button, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faHome } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import moment from "moment";

import ENV from "../../util/env-config";
import DeliveryTypes from "./DeliveryTypes";
import { AnswerStatusSelector, ThumbToggle, InlineInputIcon, Pin, Snooze } from "../../components";
import ReplacementTyreOffer from "./ReplacementTyreOffer";
import ReplacementTyreOrder from "./ReplacementTyreOrder";
import { getPriceForDisplay } from "./util";
import { renderReadyStatus, renderMedia, TYRE_POSITION, SEASONS, TYRE_TEAM_CHANNEL, getTyreSeasonIcon, QUESTION_RESULT_STATUS } from "./common";
import { getPreference, setPreference } from "../../util/preferences";
import { round2 } from "../../util/common";

import printJS from "../../lib/print-js";
import MechanicNote from "./MechanicNote";

import Service from "./service";

import "./AppointmentTyres.css";

const PRINT_SIZES = {
  A4: 1,
  LABEL: 2,
};

const AppointmentTyres = ({
  price_enabled,
  isPriceEditable,
  isEditable,
  appointment,
  displayCustomcomStatus,
  onChangeCustomerCommunication,
  includeVAT,
  vat,
  selectedCustomerComm,
  isCustomerCommunicationVisible,
  answer,
  tyreMediaVisible,
  onAnswerUpdate,
  t,
  onMediaClick,
  onTyreMediaToggle,
  onTyreReplacementUpdate,
  onTyreReplaceDelete,
  globalState,
  location,
  dashboardConfig,
  readOnlyRemarks,
  authState,
}) => {
  const [selectedTyresLocationForDBBLabels, setSelectedTyresLocationForDBBLabels] = useState("");
  const [printSize, setPrintSize] = useState(getPreference("dbb-print-size", PRINT_SIZES.A4));
  const [selectedTyreQuestion, setSelectedTyreQuestion] = useState(null);
  const [selectedTyreWidth, setSelectedTyreWidth] = useState(null);
  const [selectedTyreHeight, setSelectedTyreHeight] = useState(null);
  const [selectedTyreSize, setSelectedTyreSize] = useState(null);
  const [selectedTyreBrand, setSelectedTyreBrand] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedTyreSeason, setSelectedTyreSeason] = useState(null);
  const [selectedInStock, setSelectedInStock] = useState(true);
  const [replacementSearchResults, setReplacementSearchResults] = useState(null);
  const [replacementToOrder, setReplacementToOrder] = useState([]);
  const [replacementSearchDeliveries, setReplacementSearchDeliveries] = useState([]);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  useEffect(() => {
    if (location.is_tyre_team_enabled) {
      DeliveryTypes.getDeliveryTypes(location.id)
        .then(result => {
          setReplacementSearchDeliveries(result);
        })
        .catch(err => {
          console.log("Error getting delivery options", err);
        });
    }
  }, [location]);

  if (!answer) return;

  const dealerName = globalState.selectedDealer.name;
  const locationName = location.name;

  const renderTyrePrice = (price, disabled, onAnswerUpdate, question_result_id) => (
    <InlineInputIcon
      type="price"
      textAreaWidth="90%"
      textAreaClassName="TyreTextArea"
      value={price > 0 ? price : null}
      icon="save"
      leftIcon="euro"
      displayValueFunc={getPriceForDisplay}
      config={{
        placeholder: "0.00",
        style: { width: "4em", paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px" },
        disabled,
      }}
      iconConfig={{ className: "m-t-0" }}
      onSave={value => onAnswerUpdate({ questionId: question_result_id, shouldSave: true, type: "price", value })}
    />
  );

  const renderTyreReplacementPrice = (price, replacement, disabled, onPriceSave, onPriceChange) => (
    <InlineInputIcon
      type="price"
      value={price > 0 ? price : null}
      icon="save"
      displayValueFunc={getPriceForDisplay}
      noIcon={!replacement.tyre_replacement_id}
      leftIcon="euro"
      onSave={value => {
        onPriceSave(value);
      }}
      onChange={value => {
        onPriceChange(value);
      }}
      config={{
        placeholder: "0.00",
        style: { width: "4em", paddingBottom: "0px", paddingTop: "7px", paddingRight: "0px" },
        disabled: disabled,
      }}
    />
  );

  const getSeasonName = season => {
    switch (season) {
      case SEASONS.ALL_SEASONS:
        return "All season";
      case SEASONS.WINTER:
        return "Winter";
      case SEASONS.SUMMER:
        return "Zomer";
      default:
        return "";
    }
  };

  const handleSelectReplacement = ({ ean, delivery, channel }) => {
    const replacementSearchResultsUpdate = replacementSearchResults.map(rep =>
      rep.ean === ean && rep.delivery === delivery && rep.channel === channel ? { ...rep, checkbox_selected: !rep.checkbox_selected } : rep
    );

    setReplacementSearchResults(replacementSearchResultsUpdate);
  };

  const handleChangeSelectedHeight = height => {
    if (!height) setSelectedTyreSize("");
    setSelectedTyreHeight(height);
  };

  const handleChangeReplacementPrice = (id, price) => {
    const replacementSearchResultsUpate = replacementSearchResults.map(rep => {
      if (rep.id === id) {
        rep.price = price;
      }

      return rep;
    });

    setReplacementSearchResults(replacementSearchResultsUpate);
  };

  const handleOpenReplacementTyreOffer = question_result => {
    if (
      `${selectedTyreSize}${selectedTyreHeight}${selectedTyreWidth}` !== `${question_result.tyre.size}${question_result.tyre.height}${question_result.tyre.width}` ||
      selectedTyreBrand !== question_result.tyre.manufacturer ||
      selectedTyreSeason !== question_result.tyre.season
    ) {
      setReplacementSearchResults(null);
      setSelectedTyreWidth(question_result.tyre.width);
      setSelectedTyreHeight(question_result.tyre.height);
      setSelectedTyreSize(question_result.tyre.size);
      setSelectedTyreBrand(question_result.tyre.manufacturer);
      setSelectedTyreSeason(question_result.tyre.season);
    }

    setSelectedTyreQuestion(question_result);
  };

  const handleChangeSelectedDiscount = (discount, searchResults) => {
    const replacements = searchResults || replacementSearchResults;
    let updatedReplacementSearchResults = [];

    if (!discount) {
      setSelectedDiscount(null);

      updatedReplacementSearchResults = replacements.map(rep => ({ ...rep, discount: null, price_reduction: null, customerPrice: rep.gross_price }));
    } else {
      setSelectedDiscount(discount);

      updatedReplacementSearchResults = replacements.map(rep => {
        let currentDiscount = discount;

        if (currentDiscount.tyre_brand_discounts) {
          const foundDiscount = currentDiscount.tyre_brand_discounts.find(d => d.brand === rep.manufacturer && d.active);
          if (foundDiscount) currentDiscount = foundDiscount;
        }

        if (currentDiscount.discount != null)
          return {
            ...rep,
            discount: currentDiscount.discount ? currentDiscount.discount : null,
            price_reduction: null,
            customerPrice: rep.gross_price * (1 - currentDiscount.discount / 100),
          };
        else if (currentDiscount.net_margin != null)
          return {
            ...rep,
            price_reduction: currentDiscount.net_margin ? currentDiscount.net_margin : null,
            discount: null,
            customerPrice: round2(currentDiscount.net_margin + rep.net_price).toFixed(2),
          };
        else return rep;
      });
    }

    setReplacementSearchResults(updatedReplacementSearchResults);
  };

  const getReplacementsNotOrdered = () => {
    return ["on_car", "in_storage"]
      .map(l => {
        return answer[l]
          .map(question_result => {
            if (question_result.tyre_replacements) {
              return question_result.tyre_replacements.filter(rep => rep.tyre_team_system_number && !rep.tyre_team_order_placed);
            }

            return [];
          })
          .flat();
      })
      .flat();
  };

  const handleOpenReplacementTyreOrder = () => {
    let toOrder = [];

    const replacements = getReplacementsNotOrdered();

    replacements.forEach(rep => {
      const replacement = toOrder.find(
        tire =>
          tire.tyre_team_channel === rep.tyre_team_channel &&
          tire.tyre_team_delivery === rep.tyre_team_delivery &&
          tire.tyre_team_system_number === rep.tyre_team_system_number
      );

      if (replacement) replacement.quantity++;
      else toOrder.push({ ...rep, quantity: 1 });
    });

    toOrder.forEach(rep => (rep.initial_quantity = rep.quantity));

    setReplacementToOrder(toOrder);
  };

  const addReplacementIcon = (question_result, isEditable) => {
    if (!isEditable) return;

    return <Icon name="plus circle" onClick={() => handleOpenReplacementTyreOffer(question_result)} color="blue" style={{ cursor: "pointer" }} />;
  };

  const getTitle = position => {
    switch (position) {
      case TYRE_POSITION.CAR_FRONT_LEFT:
      case TYRE_POSITION.STORAGE_FRONT_LEFT:
        return t("front_left").message || "Front Left";

      case TYRE_POSITION.CAR_FRONT_RIGHT:
      case TYRE_POSITION.STORAGE_FRONT_RIGHT:
        return t("front_right").message || "Front Right";

      case TYRE_POSITION.CAR_REAR_LEFT:
      case TYRE_POSITION.STORAGE_REAR_LEFT:
        return t("rear_left").message || "Rear Left";

      case TYRE_POSITION.CAR_REAR_RIGHT:
      case TYRE_POSITION.STORAGE_REAR_RIGHT:
        return t("rear_right").message || "Rear Right";
      default:
        break;
    }
  };

  const getPositionInitialsDutch = position => {
    switch (position) {
      case TYRE_POSITION.CAR_FRONT_LEFT:
      case TYRE_POSITION.STORAGE_FRONT_LEFT:
        return "LV";

      case TYRE_POSITION.CAR_FRONT_RIGHT:
      case TYRE_POSITION.STORAGE_FRONT_RIGHT:
        return "RV";

      case TYRE_POSITION.CAR_REAR_LEFT:
      case TYRE_POSITION.STORAGE_REAR_LEFT:
        return "LA";

      case TYRE_POSITION.CAR_REAR_RIGHT:
      case TYRE_POSITION.STORAGE_REAR_RIGHT:
        return "RA";
      default:
        break;
    }
  };

  const getDelivery = delivery => {
    const selectedDelivery = replacementSearchDeliveries.find(d => d.id === delivery) || {};

    const { order_before, delivery_before, delivery_from } = {
      order_before: selectedDelivery.order_before ? moment(selectedDelivery.order_before).format("HH:mm") : null,
      delivery_before: selectedDelivery.delivery_before ? moment(selectedDelivery.delivery_before).format("HH:mm") : null,
      delivery_from: selectedDelivery.delivery_from ? moment(selectedDelivery.delivery_from).format("HH:mm") : null,
    };

    if (delivery.includes("PICKUPSAMEDAY"))
      return t("tyre_team_pickup_sameday", { order_before, delivery_from }).message || t("pickup_same_day").message || "Pickup same day";
    if (delivery.includes("SAMEDAY")) return t("tyre_team_sameday", { order_before, delivery_before }).message || t("same_day").message || "Same day";
    if (delivery.includes("PICKUPOVERNIGHT"))
      return t("tyre_team_pickupovernight", { order_before, delivery_from }).message || t("pickup_overnight").message || "Pickup overnight";
    if (delivery.includes("OVERNIGHT")) return t("tyre_team_overnight", { order_before, delivery_before }).message || t("overnight").message || "Overnight";

    return "";
  };

  const getDeliveryChannel = channel => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WHEEL_TYRE:
        return "Wheel-Tyre";

      case TYRE_TEAM_CHANNEL.BANDEN_EXPRESS:
        return "Banden express";

      default:
        return "Unknown";
    }
  };

  const getDeliveryColor = delivery => {
    if (delivery.includes("PICKUP")) return "orange";
    if (delivery.includes("SAMEDAY")) return "green";
    if (delivery.includes("OVERNIGHT")) return "#2185d0";
  };

  const handlePrint = () => {
    printJS({
      printable: "dbb_label_content_container",
      type: "html",
      copyStyles: true,
    });
  };

  const shortenText = (text, delimiter) => {
    if (printSize === PRINT_SIZES.A4) return text;

    if (text.length > delimiter) return `${text.slice(0, delimiter).trim()}...`;
    else return text;
  };

  const getDBBComment = position => {
    switch (position) {
      case TYRE_POSITION.CAR_FRONT_LEFT:
        return appointment.car.on_car_tyres?.dbb_fl_comment;
      case TYRE_POSITION.CAR_FRONT_RIGHT:
        return appointment.car.on_car_tyres?.dbb_fr_comment;
      case TYRE_POSITION.CAR_REAR_LEFT:
        return appointment.car.on_car_tyres?.dbb_rl_comment;
      case TYRE_POSITION.CAR_REAR_RIGHT:
        return appointment.car.on_car_tyres?.dbb_rr_comment;
      case TYRE_POSITION.STORAGE_FRONT_LEFT:
        return appointment.car.in_storage_tyres?.dbb_fl_comment;
      case TYRE_POSITION.STORAGE_FRONT_RIGHT:
        return appointment.car.in_storage_tyres?.dbb_fr_comment;
      case TYRE_POSITION.STORAGE_REAR_LEFT:
        return appointment.car.in_storage_tyres?.dbb_rl_comment;
      case TYRE_POSITION.STORAGE_REAR_RIGHT:
        return appointment.car.in_storage_tyres?.dbb_rr_comment;
      default:
        return;
    }
  };

  const renderDBBLabelsPreview = () => {
    return (
      <Modal
        className="DBBLabelsPreviewModal"
        closeOnEscape
        size="large"
        dimmer="blurring"
        open={!!selectedTyresLocationForDBBLabels}
        onClose={() => setSelectedTyresLocationForDBBLabels("")}
        closeOnDimmerClick={false}
      >
        <Modal.Actions
          style={{
            textAlign: "right",
            position: "relative",
            zIndex: 1000,
          }}
        >
          <div className="dbb_label_dropdown">
            <span>{t("select_paper_size").message || "Select paper size"}</span>
            <Dropdown
              selection
              options={[
                { text: "A4", value: PRINT_SIZES.A4 },
                { text: "90mm", value: PRINT_SIZES.LABEL },
              ]}
              value={printSize}
              placeholder={t("select_paper_size").message || "Select paper size"}
              onChange={(_evt, { value }) => {
                setPrintSize(value);
                setPreference("dbb-print-size", value);
              }}
            />
          </div>
          <Button className="dbb_label_print_btn" color="green" onClick={() => handlePrint()}>
            {t("print").message || "PRINT"}
          </Button>
          <Button className="dbb_label_print_btn" color="green" onClick={() => setSelectedTyresLocationForDBBLabels("")}>
            {t("close").message || "CLOSE"}
          </Button>
        </Modal.Actions>
        <Modal.Content scrolling id={"dbb_label_content_container"}>
          <div className={`dbb_wrapper ${printSize === PRINT_SIZES.LABEL ? "dbb_wrapper_label_size" : ""}`}>
            {answer[selectedTyresLocationForDBBLabels] &&
              answer[selectedTyresLocationForDBBLabels].map((item, i) => {
                const hasTyreSpecificationsInDescription = item.tyre.description.includes("R") && item.tyre.description.includes("/");

                return (
                  <div className={`dbb_label_box ${printSize === PRINT_SIZES.LABEL ? "dbb_label_box_label_size" : ""}`} key={i}>
                    <h1 className={`${printSize === PRINT_SIZES.LABEL ? "h1_label_size" : ""}`}>{appointment.reg_number_escaped}</h1>
                    <div className={`dbb_label_content ${printSize === PRINT_SIZES.LABEL ? "dbb_label_content_label_size" : ""}`}>
                      <div className="dbb_label_tyre">
                        <div className="dbb_label_tyre_position">
                          <span>{getPositionInitialsDutch(item.tyre_position)}</span>:
                        </div>
                        <div className="dbb_label_tyre_size">{item.tyre_profile.toFixed(1)}mm</div>
                        <div className="dbb_label_tyre_description">
                          {item.tyre.manufacturer} {shortenText(item.tyre.description, hasTyreSpecificationsInDescription ? 32 : 24)}
                          {!hasTyreSpecificationsInDescription && ` ${item.tyre.width}/${item.tyre.height}R${item.tyre.size} ${item.tyre.speed}`}
                        </div>
                      </div>
                      <div>
                        <span className="dbb_label_name">Soort:</span>
                        <span className="float-right">{getSeasonName(item.season)}: Band + stalen velg</span>
                      </div>
                      <div className={`dbb_label_comment ${printSize === PRINT_SIZES.LABEL ? "mb-28_label_size" : ""}`}>
                        <span className="dbb_label_name">Opm:</span>
                        <span className="float-right">{shortenText(getDBBComment(item.tyre_position) || "", 80)}</span>
                      </div>
                      <div className={`dbb_label_section mb-26 ${printSize === PRINT_SIZES.LABEL ? "mb-26_label_size" : ""}`}>
                        <div>
                          <span className="dbb_label_name">Dealer: </span>
                          <span className="float-right">{shortenText(dealerName, 35)}</span>
                        </div>
                        <div>
                          <span className="dbb_label_name">Plaats: </span>
                          <span className="float-right">{shortenText(locationName, 35)}</span>
                        </div>
                        <div>
                          <span className="dbb_label_name">Eigenaar:</span>
                          <span className="float-right">{shortenText(`${appointment.owner_title} ${appointment.owner_firstname} ${appointment.owner_surname}`, 33)}</span>
                        </div>
                      </div>
                      <div>
                        <span className="dbb_label_name">Berijder:</span>
                        <span className="float-right">
                          {shortenText(`${appointment.driver_title} ${appointment.driver_firstname} ${appointment.driver_surname}`, 35)}
                        </span>
                      </div>
                      <div className={`mb-24 ${printSize === PRINT_SIZES.LABEL ? "mb-24_label_size" : ""}`}>
                        <span className="dbb_label_name">{t("appointment_date").message || "Appointment Date"}:</span>
                        <span className="float-right">{moment(appointment.time_car_app).format("DD-MM-YYYY")}</span>
                      </div>
                      <div>
                        <span className="dbb_label_name">Opslaglocatie: </span>
                        <div className="dbb_location_name float-right">
                          {appointment.car.in_storage_tyres && (
                            <>
                              {shortenText(appointment.car.in_storage_tyres.dbb_location_name, 28)}

                              <br />
                              {appointment.car.in_storage_tyres.dbb_sub_location_name && `(${shortenText(appointment.car.in_storage_tyres.dbb_sub_location_name, 27)})`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  const openOrderPortal = channel => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WHEEL_TYRE:
        window.open(ENV.wheelTireOrderPortalURL, "_blank");
        break;

      case TYRE_TEAM_CHANNEL.BANDEN_EXPRESS:
        window.open(ENV.bandenExpressOrderPortalURL, "_blank");
        break;

      default:
        break;
    }
  };

  const handleSearchResults = searchResults => {
    handleChangeSelectedDiscount(selectedDiscount, searchResults);
  };

  const getTyreDescription = tyre => {
    return !tyre.description.includes(`${tyre.carry_capacity}/${tyre.speed}`) && !tyre.description.includes(`${tyre.carry_capacity}${tyre.speed}`)
      ? `${tyre.description} ${tyre.carry_capacity}${tyre.speed}`
      : tyre.description;
  };

  const handleUpdateRemark = (question_result_id, remark) => {
    Service.updateQuestionResultRemark({ question_result_id, remark })
      .then(() => {
        onAnswerUpdate({ questionId: question_result_id, shouldSave: false, type: "raw", value: remark });
      })
      .catch(error => console.error("Error updating question remark", error));
  };

  return (
    <div className="TyreAnswer">
      {printSize === PRINT_SIZES.LABEL && (
        <Helmet>
          <link rel="stylesheet" href="./dbb_label_90mm_print.css" />
        </Helmet>
      )}

      {["on_car", "in_storage"].map((l, k) => {
        if (!answer[l] || answer[l].length < 1) {
          return "";
        }

        answer[l].sort((a, b) => (a.tyre_position > b.tyre_position ? 1 : a.tyre_position < b.tyre_position ? -1 : 0));

        // init all to avoid checking if the variable exists or is an array
        answer[l].forEach(tr => {
          if (!Array.isArray(tr.tyre_replacements)) tr.tyre_replacements = [];
        });

        return (
          <span key={k}>
            <Grid className="TyreAnswerTable__head">
              <h3>
                <Label className="TyreAnswerTable__head__label" color="green">
                  <FontAwesomeIcon className="icon" icon={l === "in_storage" ? faHome : faCar} />
                </Label>
                {t(l).message || l}
                <Button size="mini" basic color="green" className="dbb_label_button" floated="right" onClick={() => setSelectedTyresLocationForDBBLabels(l)}>
                  {t("tyre_labels").message || "Tyre Labels"}
                </Button>
                {appointment.car.in_storage_tyres?.dbb_location_name && l === "in_storage" && (
                  <span className={`dbb_storage_location ${printSize === PRINT_SIZES.LABEL ? "dbb_storage_location_label_size" : ""}`}>
                    {t("storage_location").message || "Storage Location"}: <span>{appointment.car.in_storage_tyres.dbb_location_name}</span>
                  </span>
                )}
              </h3>
              <Grid.Row>
                <Grid.Column className="TyreAnswerTable__heading" width={2}>
                  {t("question").message || "Question"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading" width={1}>
                  {t("size").message || "Size"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading" width={2}>
                  {t("type").message || "Type"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading" width={1}>
                  {t("brand").message || "Brand"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading" width={1}>
                  {t("profile").message || "Profile"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading" width={1}>
                  {t("no_vat_price").message || "Excl. VAT"}
                </Grid.Column>
                {includeVAT && (
                  <Grid.Column className="TyreAnswerTable__heading" width={1}>
                    {t("vat_price").message || "Incl. VAT"}
                  </Grid.Column>
                )}
                <Grid.Column className="TyreAnswerTable__heading" width={includeVAT ? 3 : 4}>
                  {t("remarks").message || "Remarks"}
                </Grid.Column>
                <Grid.Column className="TyreAnswerTable__heading -no-padding" width={4}></Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className="TyreAnswerTable__body">
              {answer[l].map((question_result, index) => {
                const customcomSelected = selectedCustomerComm.some(scc => scc.question_result_id === question_result.id);

                const images = question_result.images?.filter(img => img.active) || [];
                const videos = question_result.videos?.filter(video => video.active) || [];

                return (
                  <React.Fragment key={question_result.id}>
                    <Grid.Row className={`TyreAnswerTable__row -item-status-${question_result.status}`}>
                      <Grid.Column className="TyreAnswerTable__column" width={2} style={{ padding: "0px 0px 0px 3px" }}>
                        {isCustomerCommunicationVisible && (
                          <Label style={{ marginRight: "5px" }}>
                            <Icon
                              style={{ cursor: "pointer", margin: "0px" }}
                              color={customcomSelected ? "green" : "red"}
                              onClick={() => onChangeCustomerCommunication(!customcomSelected, question_result.id)}
                              name={customcomSelected ? "eye" : "eye slash"}
                            />
                          </Label>
                        )}
                        {addReplacementIcon(question_result, isEditable)}
                        {getTitle(question_result.tyre_position)}
                        {getTyreSeasonIcon(question_result.tyre.season)}
                      </Grid.Column>
                      <Grid.Column className="TyreAnswerTable__column" width={1}>
                        {question_result.tyre.width}-{question_result.tyre.height}-R{question_result.tyre.size}
                      </Grid.Column>
                      <Grid.Column className="TyreAnswerTable__column" width={2}>
                        {getTyreDescription(question_result.tyre)}
                      </Grid.Column>
                      <Grid.Column className="TyreAnswerTable__column tyre_brand_column" width={1}>
                        {question_result.tyre.manufacturer}
                      </Grid.Column>
                      <Grid.Column className="TyreAnswerTable__column" width={1}>
                        {question_result.tyre_profile}mm
                      </Grid.Column>
                      <Grid.Column className="TyreAnswerTable__column" width={1}>
                        {price_enabled &&
                          question_result.tyre_replacements.length < 1 &&
                          renderTyrePrice(question_result.price, !isPriceEditable, onAnswerUpdate, question_result.id)}
                      </Grid.Column>
                      {includeVAT && (
                        <Grid.Column className="TyreAnswerTable__column" width={1}>
                          {price_enabled &&
                            question_result.tyre_replacements.length < 1 &&
                            renderTyrePrice(
                              question_result.price && question_result.price * (1 + vat / 100),
                              !isPriceEditable,
                              value => {
                                value.value = value.value / (1 + vat / 100);
                                onAnswerUpdate(value);
                              },
                              question_result.id
                            )}
                        </Grid.Column>
                      )}
                      <Grid.Column className="TyreAnswerTable__column" width={includeVAT ? 3 : 4}>
                        <InlineInputIcon
                          type="textarea"
                          textAreaWidth="81%"
                          textAreaClassName="TyreTextArea"
                          placeholder={t("answer").message}
                          value={question_result.raw ? question_result.raw.trim() : ""}
                          icon="save"
                          iconConfig={{ className: "m-t-0" }}
                          config={{ disabled: readOnlyRemarks }}
                          onSave={value => handleUpdateRemark(question_result.id, value)}
                        />
                      </Grid.Column>
                      <Grid.Column
                        textAlign="right"
                        width={4}
                        className="AppointmentChecklistGroup__item__modifiers TyreAnswerTable__column_statuses -answer-status-column -no-padding-right"
                      >
                        <AnswerStatusSelector
                          value={question_result.status}
                          disabled={!isEditable}
                          onChange={value => onAnswerUpdate({ questionId: question_result.id, type: "status", shouldSave: true, value })}
                        />

                        {(images.length > 0 || videos.length > 0) && (
                          <Label
                            color="teal"
                            style={{
                              width: "auto",
                            }}
                            onClick={() => onTyreMediaToggle(question_result.tyre_position)}
                          >
                            <Icon name="images" /> {dashboardConfig["video_enabled"] && videos.length ? videos.length + images.length : images.length}
                          </Label>
                        )}

                        {question_result.tyre_replacements.length > 0 && <span className="AnswerReadyStatus"></span>}
                        {question_result.tyre_replacements.length < 1 && (
                          <>
                            {!isCustomerCommunicationVisible && displayCustomcomStatus(question_result.status, appointment.diagnose_overview_results, question_result.id)}

                            {dashboardConfig["schedule_enabled"] && question_result.status !== QUESTION_RESULT_STATUS.OK && (
                              <Snooze
                                question_result={question_result}
                                appointment={appointment}
                                snoozeLog={question_result.snooze_history}
                                onSnooze={snoozeInfo =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "snooze",
                                    shouldSave: false,
                                    value: snoozeInfo,
                                  })
                                }
                                onDelete={() =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "snooze",
                                    shouldSave: false,
                                    value: [],
                                  })
                                }
                              />
                            )}

                            {dashboardConfig["pin_visible"] && (
                              <Pin
                                question_result={question_result}
                                appointment={appointment}
                                pinLog={question_result.pin_history}
                                onPin={pinLog => {
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "pin",
                                    shouldSave: false,
                                    value: pinLog,
                                  });
                                }}
                                onDelete={() => {
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "pin",
                                    shouldSave: false,
                                    value: [],
                                  });
                                }}
                              />
                            )}

                            {question_result.status !== QUESTION_RESULT_STATUS.OK && (
                              <ThumbToggle
                                status={question_result.customer_approved}
                                disabled={!isEditable || question_result.mechanic_fixed}
                                onClick={() =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    shouldSave: true,
                                    type: "customer_approved",
                                    value: !question_result.customer_approved,
                                  })
                                }
                              />
                            )}

                            <span className="AnswerReadyStatus">{renderReadyStatus(question_result.mechanic_fixed)}</span>
                          </>
                        )}
                      </Grid.Column>

                      {tyreMediaVisible[question_result.tyre_position] &&
                        renderMedia(isCustomerCommunicationVisible, appointment, images, videos, onMediaClick, onAnswerUpdate, true, [], question_result.id, location)}
                    </Grid.Row>

                    {question_result.mechanic_notes && !isCustomerCommunicationVisible && <MechanicNote note={question_result.mechanic_notes} />}

                    {question_result.tyre_replacements.map((tr, tri) => (
                      <Grid.Row key={question_result.id + "_" + tri} className={`TyreAnswerTable__row -item-status-${question_result.status}`}>
                        <Grid.Column className="TyreAnswerTable__column" width={2} style={{ color: "#2185d0", fontWeight: "bold" }}>
                          <p style={{ paddingTop: "7px", paddingBottom: "5px" }}>&nbsp;&nbsp;&nbsp;{t("replace_with").message || "Replace with"}:</p>
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column" width={1}>
                          {tr.tyre && <p style={{ paddingTop: "7px" }}>{tr.tyre.width + "-" + tr.tyre.height + "-R" + tr.tyre.size}</p>}
                        </Grid.Column>

                        <>
                          <Grid.Column className="TyreAnswerTable__column" width={2}>
                            {tr.tyre && <p style={{ paddingTop: "7px" }}>{getTyreDescription(tr.tyre)}</p>}
                          </Grid.Column>
                          <Grid.Column className="TyreAnswerTable__column" width={1}>
                            {tr.tyre && <p style={{ paddingTop: "7px" }}>{tr.tyre.manufacturer}</p>}
                          </Grid.Column>
                        </>

                        {<Grid.Column className="TyreAnswerTable__column" width={1}></Grid.Column>}

                        <Grid.Column className="TyreAnswerTable__column" width={1}>
                          {price_enabled &&
                            renderTyreReplacementPrice(
                              tr.price,
                              tr,
                              !isPriceEditable,
                              value => onTyreReplacementUpdate(question_result.id, tri, { field: "price_save", value }),
                              value => {
                                onTyreReplacementUpdate(question_result.id, tri, { field: "price_change", value });
                              }
                            )}
                        </Grid.Column>
                        {includeVAT && (
                          <Grid.Column className="TyreAnswerTable__column" width={1}>
                            {price_enabled &&
                              renderTyreReplacementPrice(
                                tr.price && tr.price * (1 + vat / 100),
                                tr,
                                !isPriceEditable,
                                value => onTyreReplacementUpdate(question_result.id, tri, { field: "price_save", value: value / (1 + vat / 100) }),
                                value => {
                                  onTyreReplacementUpdate(question_result.id, tri, { field: "price_change", value: value && value / (1 + vat / 100) });
                                }
                              )}
                          </Grid.Column>
                        )}
                        <Grid.Column className="TyreAnswerTable__column" width={includeVAT ? 3 : 4}>
                          <InlineInputIcon
                            type="textarea"
                            textAreaClassName="TyreTextArea"
                            placeholder={t("answer").message}
                            value={tr.remark ? tr.remark.trim() : ""}
                            icon="save"
                            noIcon={!tr.tyre_replacement_id}
                            config={{ style: { paddingTop: "8px", width: "81%" }, disabled: readOnlyRemarks }}
                            iconConfig={{ className: "m-t-0" }}
                            onSave={value => onTyreReplacementUpdate(question_result.id, tri, { field: "remark_save", value })}
                            onChange={value => {
                              onTyreReplacementUpdate(question_result.id, tri, { field: "remark_change", value });
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          textAlign="right"
                          width={4}
                          className="AppointmentChecklistGroup__item__modifiers TyreAnswerTable__column -answer-status-column -no-padding-right"
                        >
                          {tr.tyre_replacement_id && !isCustomerCommunicationVisible && (
                            <>
                              {isEditable && (
                                <Label style={{ float: "left" }} onClick={() => onTyreReplaceDelete(question_result.id, tri)}>
                                  <Icon color="red" name="trash" />
                                </Label>
                              )}

                              {tr.tyre_team_system_number && location.is_tyre_team_enabled && (tr.tyre_team_order_placed || isEditable) && (
                                <Popup
                                  flowing
                                  position="top center"
                                  trigger={
                                    <Label
                                      className={!authState.user.tyre_ordering_enabled ? " -order-disabled" : ""}
                                      style={
                                        tr.tyre_team_order_placed
                                          ? { float: "left", color: "#FFF", backgroundColor: getDeliveryColor(tr.tyre_team_delivery) }
                                          : { float: "left" }
                                      }
                                      onClick={
                                        authState.user.tyre_ordering_enabled
                                          ? tr.tyre_team_order_placed
                                            ? () => openOrderPortal(tr.tyre_team_channel)
                                            : handleOpenReplacementTyreOrder
                                          : null
                                      }
                                    >
                                      <Icon name="shopping cart" />
                                    </Label>
                                  }
                                  content={
                                    <>
                                      {getDelivery(tr.tyre_team_delivery)}
                                      <br />
                                      {t("ordering_channel").message || "Ordering Channel"}: {getDeliveryChannel(tr.tyre_team_channel)}
                                    </>
                                  }
                                />
                              )}
                            </>
                          )}

                          {!isCustomerCommunicationVisible &&
                            displayCustomcomStatus(2, appointment.diagnose_overview_results, question_result.id, tr.tyre_replacement_id)}

                          {tr.tyre_replacement_id && (
                            <>
                              {question_result.status !== QUESTION_RESULT_STATUS.OK && (
                                <ThumbToggle
                                  status={tr.customer_answer}
                                  disabled={!isEditable || tr.mechanic_fixed}
                                  onClick={() => {
                                    onTyreReplacementUpdate(question_result.id, tri, { field: "customer_answer", value: !tr.customer_answer });
                                  }}
                                />
                              )}

                              <span className="AnswerReadyStatus">{renderReadyStatus(tr.mechanic_fixed)}</span>
                            </>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </React.Fragment>
                );
              })}
            </Grid>
          </span>
        );
      })}
      {renderDBBLabelsPreview()}

      {selectedTyreQuestion && (
        <ReplacementTyreOffer
          onSelectReplacement={handleSelectReplacement}
          onChangeReplacementPrice={handleChangeReplacementPrice}
          width={selectedTyreWidth}
          height={selectedTyreHeight}
          size={selectedTyreSize}
          manufacturer={selectedTyreBrand}
          selectedDiscount={selectedDiscount}
          season={selectedTyreSeason}
          question_result_id={selectedTyreQuestion.id}
          selectedTyreQuestionBrand={selectedTyreQuestion?.tyre?.manufacturer}
          in_stock={selectedInStock}
          search_results={replacementSearchResults}
          isTyreTeamEnabled={location.is_tyre_team_enabled}
          location_id={location.id}
          dealer_id={location.dealer_id}
          onSearchResults={handleSearchResults}
          close={() => setSelectedTyreQuestion(null)}
          deliveries={replacementSearchDeliveries}
          selectedDeliveries={selectedDeliveries}
          onChangeSelectedWidth={setSelectedTyreWidth}
          onChangeSelectedHeight={handleChangeSelectedHeight}
          onChangeSelectedSize={setSelectedTyreSize}
          onChangeSelectedBrand={setSelectedTyreBrand}
          onChangeSelectedDiscount={handleChangeSelectedDiscount}
          onChangeSelectedSeason={setSelectedTyreSeason}
          onChangeSelectedInStock={setSelectedInStock}
          onChangeDeliveries={setReplacementSearchDeliveries}
          onChangeSelectedDeliveries={setSelectedDeliveries}
        />
      )}

      {replacementToOrder.length > 0 && (
        <ReplacementTyreOrder
          isOpen={true}
          replacementToOrder={replacementToOrder}
          onUpdateReplacementToOrder={setReplacementToOrder}
          close={() => setReplacementToOrder([])}
          check_id={answer["on_car"][0].check_id}
          deliveries={replacementSearchDeliveries}
          onChangeDeliveries={setReplacementSearchDeliveries}
          location_id={location.id}
          is_tyre_team_autofill_price_ref_on_order={location.is_tyre_team_autofill_price_ref_on_order}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps)(AppointmentTyres));
