import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getCar(carID) {
    return axios.post(
      ENV.carBaseUrl + "/cars/details",
      {
        id: carID,
      },
      this.requestConfig()
    );
  }

  static getCarAppointments(carID) {
    return axios.post(
      ENV.carBaseUrl + "/cars/checks/list",
      {
        id: carID,
      },
      this.requestConfig()
    );
  }

  static setMCCCar(requestData) {
    return axios.post(ENV.carBaseUrl + "/cars/mcc/update", requestData, this.requestConfig());
  }

  static getRDW(requestData) {
    return axios.post(ENV.carBaseUrl + "/cars/rdw/details", requestData, this.requestConfig());
  }

  static appendAttachment(requestData) {
    return axios.post(ENV.appointmentApiBase + "/cars/attachment/append", requestData, this.requestConfig());
  }

  static deleteAttachment(url) {
    return axios.post(ENV.carBaseUrl + "/cars/attachment/delete", { url }, this.requestConfig());
  }

  static getCustomersByCar(requestData) {
    return axios.post(ENV.apiBaseB + "/customers/list_by_car", requestData, this.requestConfig());
  }

  static getPinsByCarId(requestData) {
    return axios.post(ENV.appointmentApiBase + "/pins/list_by_car_id", requestData, this.requestConfig());
  }

  static getSnoozesByCarId(requestData) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/list_by_car_id", requestData, this.requestConfig());
  }

  static uploadAttachment(requestData) {
    return axios.post(ENV.carBaseUrl + "/notes/attachments/upload", requestData, this.requestConfig());
  }

  static createNote(requestData) {
    return axios.post(ENV.carBaseUrl + "/notes/create", requestData, this.requestConfig());
  }

  static updateNote(requestData) {
    return axios.post(ENV.carBaseUrl + "/notes/update", requestData, this.requestConfig());
  }

  static deleteNote(requestData) {
    return axios.post(ENV.carBaseUrl + "/notes/delete", requestData, this.requestConfig());
  }

  static getDBBLink(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/portal_dbb", requestData, this.requestConfig());
  }

  static deleteInStorageTires(requestData) {
    return axios.post(ENV.carBaseUrl + "/tyres/storage_set/remove", requestData, this.requestConfig());
  }
}

export default Service;
