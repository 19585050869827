import React, { Component } from "react";
import { Button, Icon, Modal, Checkbox, Segment, Grid, Header } from "semantic-ui-react";
import { copyToClipboard } from "../../util/common";
import DealerForm from "./DealerForm";
import DealerSystemForm from "./DealerSystemForm";
import CustomConfirm from "../../components/CustomConfirm";
import Service from "./service";
import KeyloopConfiguration from "./KeyloopConfiguration";
import PonOilConfiguration from "./PonOilConfiguration";
import Configuration from "./Configuration";
import LegalForm from "./LegalForm";
import Can from "../Can";
import { ROLES } from "../Users/roles";
import DealerInvoicingSettings from "./DealerInvoicingSettings";

class DealerDetail extends Component {
  state = {
    isSubmit: false,
    missingField: false,
    dealer: this.props.dealer,
    showDeleteDealerConfirm: false,
    isDeletingDealer: false,
    deletingDealerError: "",
    showDPOConfirmation: false,
  };

  escapeModal = evt => {
    if (evt.keyCode === 27) {
      this.props.onHideDetail();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escapeModal, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeModal, false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== undefined) {
      this.setState({ isSubmit: nextProps.loading });
    }
  }

  handleActiveChange = (e, data) => {
    let { dealer } = this.state;
    dealer.active = data.checked;
    this.handleDealerChange(dealer);
  };

  handleDealerChange = dealer => {
    this.setState({ dealer });
  };

  handleCopyKey = key => {
    copyToClipboard(key);
  };

  handleGenerateSvcKey = () => {
    const { onGenerateSvcKey, dealer } = this.props;
    onGenerateSvcKey(dealer.id);
  };

  handleHasPonOilRequestMissingFields = () => {
    const { mode } = this.props;
    const { has_pon_oil_password, is_pon_oil_enabled, pon_oil_username, pon_oil_password } = this.state.dealer;

    if (!is_pon_oil_enabled || mode === "create") return false;

    if (has_pon_oil_password && !pon_oil_username) return true;
    if (!has_pon_oil_password && !(pon_oil_username && pon_oil_password)) return true;

    return false;
  };

  handleSave = () => {
    let { dealer } = this.state;
    const { onSave, mode } = this.props;

    if (!dealer || !dealer.name || this.handleHasPonOilRequestMissingFields()) {
      this.setState({ missingField: true });
      return;
    }

    if (mode === "create") this.setState({ showDPOConfirmation: true });
    else
      this.setState({ missingField: false, isSubmit: true }, () => {
        onSave(dealer);
      });
  };

  handleDeleteDealer = () => {
    const { id } = this.state.dealer;
    this.setState(
      {
        isDeletingDealer: true,
        deletingDealerError: "",
      },
      () => {
        Service.deleteDealer(id)
          .then(response => {
            this.props.deleteDealer(id);
            this.setState(
              {
                showDeleteDealerConfirm: false,
                isDeletingDealer: false,
                deletingDealerError: "",
              },
              () => {
                this.props.onHideDetail();
              }
            );
          })
          .catch(err => {
            this.setState({
              isDeletingDealer: false,
              deletingDealerError: err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error Occurred",
            });

            this.props.setAlert({
              type: "error",
              title: err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error Occurred",
            });
          });
      }
    );
  };

  toggleShowHideDeleteDealerConfirm = () => {
    this.setState(prevState => ({
      showDeleteDealerConfirm: !prevState.showDeleteDealerConfirm,
    }));
  };

  renderDeleteDealerConfirm = () => {
    const { showDeleteDealerConfirm, isDeletingDealer, deletingDealerError } = this.state;
    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteDealerConfirm}
        confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        error={deletingDealerError}
        isLoading={isDeletingDealer}
        handleCancel={this.toggleShowHideDeleteDealerConfirm}
        handleConfirm={this.handleDeleteDealer}
      />
    );
  };

  renderDPOConfirmation = () => {
    let { showDPOConfirmation, dealer } = this.state;

    return (
      <CustomConfirm
        type="warning"
        isOpen={showDPOConfirmation}
        confirmMsg={this.props.t("create_dpo_reminder").message || "You need to assign a DPO for this dealer."}
        handleConfirm={() => {
          this.setState({ missingField: false, isSubmit: true, showDPOConfirmation: false }, () => {
            this.props.onSave(dealer);
          });
        }}
        hideCancelButton
      />
    );
  };

  render() {
    const { generatedSvcKey, mode, t, user, isAdmin, onHideDetail } = this.props;
    const { missingField, dealer, isSubmit } = this.state;
    return (
      <div className="DealerDetail">
        <Modal open={true} closeOnDimmerClick={false} size={mode === "create" ? "small" : "fullscreen"}>
          <Modal.Header>
            <div>
              {mode === "create" ? t("add_dealer").message || "Add dealer" : t("edit_dealer").message || "Edit dealer"}
              <Checkbox checked={dealer.active} name="active" label={t("active").message || "Active"} className="-pull-right" onChange={this.handleActiveChange} />
            </div>
          </Modal.Header>

          <Modal.Content scrolling={mode === "create"}>
            <Grid columns="equal">
              <Grid.Column>
                <Segment placeholder>
                  <Header as="h5">
                    <Icon name="warehouse" />
                    <Header.Content>{t("dealer_settings").message || "Dealer settings"}</Header.Content>
                  </Header>
                  <Segment>
                    <DealerForm missingField={missingField} dealer={dealer} user={user} mode={mode} onChange={this.handleDealerChange} isAdmin={this.props.isAdmin} />
                  </Segment>
                </Segment>
                {mode !== "create" && (
                  <>
                    <Segment>
                      <Header as="h5">
                        <Icon name="legal" />
                        <Header.Content>{t("GDPR_AVG").message || "GDPR - AVG"}</Header.Content>
                      </Header>
                      <Segment>
                        <LegalForm dealer={dealer} onChange={this.handleDealerChange} />
                      </Segment>
                    </Segment>

                    <DealerInvoicingSettings dealer={dealer} onUpdateDealer={this.handleDealerChange} />
                  </>
                )}
              </Grid.Column>
              {mode !== "create" && (
                <Grid.Column>
                  <Segment placeholder>
                    <Header as="h5">
                      <Icon name="info" />
                      <Header.Content>{t("dealer_sys_info").message || "System info"}</Header.Content>
                    </Header>
                    <Segment>
                      <DealerSystemForm dealer={dealer} t={t} onChange={this.handleDealerChange} isAdmin={isAdmin} />
                    </Segment>
                  </Segment>
                  <Segment>
                    <Header as="h5">
                      <Icon name="wrench" />
                      <Header.Content>{t("configuration").message || "Configuration"}</Header.Content>
                    </Header>
                    <Segment>
                      <Configuration handleChange={this.handleDealerChange} dealer={dealer} />
                    </Segment>
                  </Segment>

                  <Segment>
                    <Header as="h5">
                      <Icon name="wrench" />
                      <Header.Content>{t("keyloop_configuration").message || "Keyloop configuration"}</Header.Content>
                      <Header.Subheader style={{ color: "gray", marginTop: "10px" }}>
                        {t("cdk_dealer_notice").message || "Business unit for each location must also be provided"}
                      </Header.Subheader>
                    </Header>
                    <Segment>
                      <KeyloopConfiguration t={t} handleChange={this.handleDealerChange} dealer={dealer} />
                    </Segment>
                  </Segment>

                  <Segment>
                    <Header as="h5">
                      <Icon name="wrench" />
                      <Header.Content>{t("pon_oil_configuration").message || "PON Oil configuration"}</Header.Content>
                    </Header>
                    <Segment>
                      <PonOilConfiguration t={t} handleChange={this.handleDealerChange} dealer={dealer} missingField={missingField} />
                    </Segment>
                  </Segment>
                </Grid.Column>
              )}
            </Grid>
            {mode !== "create" && isAdmin && (
              <Grid columns="equal">
                <Grid.Column>
                  <Button color="green" onClick={this.handleGenerateSvcKey} floated="left">
                    {" "}
                    {t("generate_secret").message || "GENERATE SECRET"}
                  </Button>
                  {generatedSvcKey && (
                    <span onClick={() => this.handleCopyKey(generatedSvcKey)} className="service-key-generated -cursor-pointer">
                      {generatedSvcKey} <Icon name="copy outline"></Icon>
                    </span>
                  )}
                </Grid.Column>
              </Grid>
            )}
          </Modal.Content>
          <Modal.Actions>
            {mode !== "create" && user?.role_id === ROLES.SUPER_ADMIN && (
              <Can I="delete" the="dealers">
                <Button color="red" floated="left" onClick={this.toggleShowHideDeleteDealerConfirm}>
                  <Icon name="trash alternate outline" /> {t("delete").message || "DELETE"}
                </Button>
              </Can>
            )}
            <Button
              style={{ backgroundColor: "#c2c4c6" }}
              floated={mode === "create" ? "left" : null}
              onClick={() => {
                onHideDetail();
              }}
            >
              <Icon name="close" /> {t("discard").message || "DISCARD"}
            </Button>
            <Button color="green" onClick={this.handleSave} loading={isSubmit} disabled={isSubmit}>
              <Icon name="checkmark" /> {t("save").message || "SAVE"}
            </Button>
          </Modal.Actions>
        </Modal>
        {this.renderDeleteDealerConfirm()}
        {this.renderDPOConfirmation()}
      </div>
    );
  }
}

export default DealerDetail;
