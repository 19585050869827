import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static listCredentials(dealer_location_id) {
    return axios.post(ENV.tyreteamBaseURL + "/credentials/list", { dealer_location_id }, this.requestConfig());
  }

  static addCredential(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/credentials/add", requestData, this.requestConfig());
  }

  static updateCredential(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/credentials/update", requestData, this.requestConfig());
  }

  static deleteCredential(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/credentials/delete", requestData, this.requestConfig());
  }

  static validateCredentials(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/credentials/validate", requestData, this.requestConfig());
  }

  static getPlanitPlanningEstablishmentIDs(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/planit/locations", requestData, this.requestConfig());
  }

  static uploadCompanyStamp(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_company_stamp", requestData, this.requestConfig());
  }

  static saveInvoicingAccount(requestData) {
    return axios.post(ENV.exactBase + "/accounts/save", requestData, this.requestConfig());
  }

  static linkInvoicingAccount(requestData) {
    return axios.post(ENV.exactBase + "/accounts/link", requestData, this.requestConfig());
  }

  static retrieveInvoicingAccount(requestData) {
    return axios.post(ENV.exactBase + "/accounts/retrieve", requestData, this.requestConfig());
  }
}

export default Service;
