import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getTags() {
    return axios.get(ENV.systemBase + "/tags/list", this.requestConfig());
  }

  static getLocationChecklists(id) {
    return axios.post(ENV.apiBaseB + "/locations/details?embed=checklist", { id: id }, this.requestConfig());
  }

  static getDealer(id) {
    return axios.post(ENV.apiBaseB + "/dealers/details?embed=checklist", { id: id }, this.requestConfig());
  }

  static getDefaultChecklists(tags) {
    let params = {};
    if (tags && tags.length > 0) {
      params.tags = tags;
    }
    return axios.post(ENV.dealerBaseUrl + "/checklists/mylist?limit=1000", params, this.requestConfig());
  }

  static storeChecklist(requestParams) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/create", requestParams, this.requestConfig());
  }

  static updateChecklist(data) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/update", data, this.requestConfig());
  }

  static deleteChecklist(checklist_id) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/delete", { id: checklist_id }, this.requestConfig());
  }

  static reorder(item, dealer_location_id) {
    let data = {
      checklist_id: item.id,
      location_id: dealer_location_id,
      display_order: item.display_order,
    };
    return axios.post(ENV.dealerBaseUrl + "/checklists/order/update", data, this.requestConfig());
  }

  static locationAttach(checklist_id, dealer_location_id) {
    let data = {
      checklist_id: checklist_id,
      dealer_location_id: dealer_location_id,
    };
    return axios.post(ENV.dealerBaseUrl + "/checklists/location/attach", data, this.requestConfig());
  }

  static locationDetach(checklist_id, dealer_location_id) {
    let data = {
      checklist_id: checklist_id,
      dealer_location_id: dealer_location_id,
    };
    return axios.post(ENV.dealerBaseUrl + "/checklists/location/detach", data, this.requestConfig());
  }

  static dealerAttach(checklist_id, dealer_id) {
    let data = {
      checklist_id: checklist_id,
      dealer_id: dealer_id,
    };
    return axios.post(ENV.dealerBaseUrl + "/checklists/dealer/attach", data, this.requestConfig());
  }

  static dealerDetach(checklist_id, dealer_id) {
    let data = {
      checklist_id: checklist_id,
      dealer_id: dealer_id,
    };
    return axios.post(ENV.dealerBaseUrl + "/checklists/dealer/detach", data, this.requestConfig());
  }

  static getChecklist(checklist_id) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/view?embed=question_group", { id: checklist_id, embed: "attached_to,question_group" }, this.requestConfig());
  }

  static getTemplatesShort() {
    return axios.get(ENV.dealerBaseUrl + "/checklists/template/list_short", this.requestConfig());
  }

  static getBrands() {
    return axios.get(ENV.carBaseUrl + "/brands/list", this.requestConfig());
  }

  static getQuestionsByDealer(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/questions/search", { id: dealer_id }, this.requestConfig());
  }

  static getQuestions() {
    return axios.post(ENV.dealerBaseUrl + "/questions/default", {}, this.requestConfig());
  }

  // added by me
  static extendDefaultChecklist(parent_id, name, currentList, valueOfID) {
    if (currentList === "dealer") {
      return axios.post(ENV.dealerBaseUrl + "/checklists/extend", { parent_id, name, dealer_id: valueOfID }, this.requestConfig());
    } else {
      return axios.post(ENV.dealerBaseUrl + "/checklists/extend", { parent_id, name, dealer_location_id: valueOfID }, this.requestConfig());
    }
  }
}

export default Service;
